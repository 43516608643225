<template>
  <section>
    <user-login
      :mode="mode"
    />
  </section>
</template>

<script>
  import api from '@/api/co.api'
  import app from '@/api/co.app'
  import lib from '@/api/co.lib'

  export default {
    metaInfo: { title: '登录' },
    components: {
      UserLogin: () => import('@/pages/sections/my/UserLogin.vue')
    },
    mixins: [
    ],
    data () {
      return {
        mode: 'qrcode'
      }
    },
    created () {
      app.on(app.event.LOGIN_PHONE, this.changeLoginMode)
      app.on(app.event.LOGIN_QRCODE, this.changeLoginMode)
      const name = api.link.login.name
      const params = JSON.parse(lib.cookie.get(name))
      this.mode = params.mode || 'qrcode'
    },
    destroyed () {
      app.off(app.event.LOGIN_PHONE)
      app.off(app.event.LOGIN_QRCODE)
    },
    methods: {
      changeLoginMode (ev) {
        this.mode = ev.mode
      },
    }
  }
</script>
